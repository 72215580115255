import { standardizedFetch } from '../../utils/standardizedFetch';
import SCOPE_TYPES from '../../utils/sentryScopeTypes';
import jobTitleToSlug from '../../utils/jobTitleToSlug';

export const createBraintreeTransaction = async ({ order_token, ...payload }) => standardizedFetch({
  uri: '/api/braintree/transactions',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: { order_token },
  ...payload
});

export const getBrand = async ({ brand, ...payload }) => standardizedFetch({
  uri: `/api/brand/${brand}`,
  fingerprint: '/api/brand/[brand]',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getBrandsByTaxon = async ({ taxon, ...payload }) => standardizedFetch({
  uri: `/api/brands/taxons/${taxon}`,
  fingerprint: '/api/brands/taxons/[taxon]',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getBrands = async (payload) => standardizedFetch({
  uri: '/api/brands',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const nextCheckout = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/checkouts/${id}/next`,
  fingerprint: '/api/checkouts/[id]/next',
  method: 'PUT',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const advanceCheckout = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/checkouts/${id}/advance`,
  fingerprint: '/api/checkouts/[id]/advance',
  method: 'PUT',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const completeCheckout = async ({ id, body, ...payload }) => standardizedFetch({
  uri: `/api/checkouts/${id}/complete`,
  fingerprint: '/api/checkouts/[id]/complete',
  method: 'PUT',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  body,
  ...payload
});

export const updateCheckout = async ({
  id,
  hold_state = false,
  address_verification = false,
  body,
  ...payload
}) => standardizedFetch({
  uri: `/api/checkouts/${id}`,
  fingerprint: '/api/checkouts/[id]',
  method: 'PATCH',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: { hold_state, address_verification },
  body,
  ...payload
});

export const createAfterpay = async ({ order_number, payment_method_id }) => standardizedFetch({
  uri: 'api/solidus_afterpay',
  fingerprint: '/api/solidus_afterpay',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: { order_number, payment_method_id }
});

export const getContents = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/contents/${id}`,
  fingerprint: '/api/contents/[id]',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getModule = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/modules/${id}`,
  fingerprint: '/api/modules/$[id]',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getCredits = async ({ page, ...payload }) => standardizedFetch({
  uri: '/api/credits',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: { page },
  ...payload
});

export const createForgotPassword = async (payload) => standardizedFetch({
  uri: '/api/forgot-password',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const login = async ({ order_number, ...payload }) => standardizedFetch({
  uri: '/api/login',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: { order_number },
  ...payload
});

export const getMinis = async (payload) => standardizedFetch({
  uri: '/api/minis',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const createMini = async (payload) => standardizedFetch({
  uri: '/api/minis',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const deleteMini = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/minis/${id}`,
  fingerprint: '/api/minis/[id]',
  method: 'DELETE',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const updateMini = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/minis/${id}`,
  fingerprint: '/api/minis/[id]',
  method: 'PATCH',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getNavigation = async (payload) => standardizedFetch({
  uri: '/api/navigation',
  method: 'GET',
  scopes: [],
  ...payload
});

export const getOrder = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/order/${id}`,
  fingerprint: '/api/order/[id]',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const removeCoupon = async ({ order_number, coupon, ...payload }) => standardizedFetch({
  uri: `/api/orders/${order_number}/coupon_codes/${coupon}`,
  fingerprint: '/api/orders/[order_number]/coupon_codes/[coupon]',
  method: 'DELETE',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const updateLineItem = async ({
  id, order_number, quantity, ...payload
}) => standardizedFetch({
  uri: `/api/orders/${order_number}/line_items/${id}`,
  fingerprint: '/api/orders/[order_number]/line_items/[id]',
  method: 'PATCH',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: { quantity },
  ...payload
});

export const removeLineItem = async ({
  id, order_number, ...payload
}) => standardizedFetch({
  uri: `/api/orders/${order_number}/line_items/${id}`,
  fingerprint: '/api/orders/[order_number]/line_items/[id]',
  method: 'DELETE',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const clearCart = async ({ order_number, ...payload }) => standardizedFetch({
  uri: `/api/orders/${order_number}/empty`,
  fingerprint: '/api/orders/[order_number]/empty',
  method: 'PUT',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getCart = async ({ order_number, ...payload }) => standardizedFetch({
  uri: `/api/orders/${order_number}`,
  fingerprint: '/api/orders/[order_number]',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const updateOrder = async ({ order_number, ...payload }) => standardizedFetch({
  uri: `/api/orders/${order_number}`,
  fingerprint: '/api/orders/[order_number]',
  method: 'PATCH',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const addToCart = async (payload) => standardizedFetch({
  uri: '/api/orders/cart',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getCurrentCart = async (payload) => standardizedFetch({
  uri: '/api/orders/current',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getOrders = async ({ page = 1, ...payload }) => standardizedFetch({
  uri: '/api/orders',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: { page },
  ...payload
});

export const getPage = async ({ path, ...payload }) => standardizedFetch({
  uri: `/api/pages${path}`,
  fingerprint: '/api/pages/[path]',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getPageParams = async ({ params, ...payload }) => standardizedFetch({
  uri: '/api/pages/list',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: params,
  ...payload
});

export const getPreview = async ({ id, cid, ...payload }) => standardizedFetch({
  uri: `/api/previews/${id}${cid ? '/' + cid : ''}`,
  fingerprint: '/api/previews/[id]/[cid]',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getProduct = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/products/${id}`,
  fingerprint: '/api/products/[id]',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getProductStructuredData = async ({ slug, ...payload }) => standardizedFetch({
  uri: `/api/pdp-structured-data/${slug}`,
  fingerprint: '/api/pdp-structured-data/[slug]',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getHasProductRecommendation = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/products/has-recommendation`,
  fingerprint: '/api/products/has-recommendation',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: {
    'id': id
  },
  ...payload
});

export const getRecentlyViewed = async ({ session_id, ...payload }) => standardizedFetch({
  uri: '/api/recently_viewed',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: {
    'recently_viewed[session_id]': session_id
  },
  ...payload
});

export const createRecentlyViewed = async (payload) => standardizedFetch({
  uri: '/api/recently_viewed',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const register = async ({ order_number, ...payload }) => standardizedFetch({
  uri: '/api/register',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: { order_number },
  ...payload
});

export const resetPassword = async (payload) => standardizedFetch({
  uri: '/api/reset-password',
  method: 'PUT',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getReturn = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/returns/${id}`,
  fingerprint: '/api/returns/$[id]',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getReturns = async (payload) => standardizedFetch({
  uri: '/api/returns',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const createGiftWrap = async ({
  shipment_number, order_number, ...payload
}) => standardizedFetch({
  uri: `/api/shipments/${shipment_number}/giftwrap`,
  fingerprint: '/api/shipments/[shipment_number]/giftwrap',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: { order_number },
  ...payload
});

export const removeGiftWrap = async ({
  shipment_number, order_number, ...payload
}) => standardizedFetch({
  uri: `/api/shipments/${shipment_number}/giftwrap`,
  fingerprint: '/api/shipments/[shipment_number]/giftwrap',
  method: 'DELETE',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: { order_number },
  ...payload
});

export const joinWaitlist = async (payload) => standardizedFetch({
  uri: '/api/stock_requests',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const subscribeToEmails = async (payload) => standardizedFetch({
  uri: '/api/subscriptions',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getUser = async (payload) => standardizedFetch({
  uri: '/api/user',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const deleteAddress = async ({ id, address_id, ...payload }) => standardizedFetch({
  uri: `/api/users/${id}/address_book`,
  fingerprint: '/api/users/[id]/address_book',
  method: 'DELETE',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: { address_id },
  ...payload
});

export const updateAddress = async ({ id, address_id, ...payload }) => standardizedFetch({
  uri: `/api/users/${id}/address_book`,
  fingerprint: '/api/users/[id]/address_book',
  method: 'PATCH',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  queryParams: { address_id },
  ...payload
});

export const createAddress = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/users/${id}/address_book`,
  fingerprint: '/api/users/[id]/address_book',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const updateUser = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/users/${id}`,
  fingerprint: '/api/users/[id]',
  method: 'PATCH',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const subscribe = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/users/${id}/subscribe`,
  fingerprint: '/api/users/[id]/subscribe',
  method: 'PUT',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const unsubscribe = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/users/${id}/unsubscribe`,
  fingerprint: '/api/users/[id]/unsubscribe',
  method: 'PUT',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const removePaymentMethod = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/wallet/${id}`,
  fingerprint: '/api/wallet/[id]',
  method: 'DELETE',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const setDefaultPaymentMethod = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/wallet/${id}`,
  fingerprint: '/api/wallet/[id]',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const deleteWishedProduct = async ({ id, ...payload }) => standardizedFetch({
  uri: `/api/wished_products/${id}`,
  fingerprint: '/api/wished_products/[id]',
  method: 'DELETE',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const createWishedProduct = async (payload) => standardizedFetch({
  uri: '/api/wished_products',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getWishedProducts = async (payload) => standardizedFetch({
  uri: '/api/wished_products/mine',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getDefaultWishlist = async (payload) => standardizedFetch({
  uri: '/api/wishlist',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const identifyKustomer = async ({ id = null, ...payload }) => {
  if (!global.window.Kustomer) return;

  global.window.Kustomer.clear();

  if (id) {
    const kustomerChatJWT = await standardizedFetch({
      uri: '/api/kustomer',
      method: 'POST',
      scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
      body: {
        externalId: id,
        iat: new Date().getTime()
      },
      ...payload
    }).then(({ jwt }) => jwt);

    global.window.Kustomer.identify(kustomerChatJWT);
  }

  global.window.Kustomer.start();
};

export const getMobileNavigation = async (payload) => standardizedFetch({
  uri: '/api/mobile-navigation',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const getPromoBar = async (payload) => standardizedFetch({
  uri: '/api/promo-bar',
  method: 'GET',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  ...payload
});

export const setKlaviyoSubscription = async (body, payload) => standardizedFetch({
  uri: '/api/sms-opt-in',
  method: 'POST',
  scopes: [SCOPE_TYPES.SERVICES.FRONTEND],
  body,
  ...payload
})
