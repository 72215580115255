export const EGROWTHBOOK_FEATURES_FLAGS = {
  PROMO_CODE: 'mweb-pdp-promo-code',
  PROMO_TEXT: 'mweb-pdp-promo-text',
  PROMO_BANNER: 'mweb-pdp-promo-banner',
  PDP_EXPERIENCE: 'mweb-pdp-experience',
  DIGITAL_CATALOG_SRC: 'digital-catalog-src',
  DIGITAL_CATALOG_ACTIVE: 'digital-catalog-active',
};

export const DEFAULT_GROWTHBOOK_FEATURES = {
  [EGROWTHBOOK_FEATURES_FLAGS.PROMO_CODE]: 'New10',
  [EGROWTHBOOK_FEATURES_FLAGS.PROMO_TEXT]: 'Get 10% off any order over 50$',
  [EGROWTHBOOK_FEATURES_FLAGS.PROMO_BANNER]: true,
  [EGROWTHBOOK_FEATURES_FLAGS.PDP_EXPERIENCE]: true,
  [EGROWTHBOOK_FEATURES_FLAGS.DIGITAL_CATALOG_SRC]: '',
  [EGROWTHBOOK_FEATURES_FLAGS.DIGITAL_CATALOG_ACTIVE]: false,
};
