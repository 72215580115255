export const detectDevice = (ctx) => {
  let isMobileDevice = false;
  try {
    isMobileDevice =
      ctx?.req &&
      ctx?.req?.headers['user-agent']?.match?.(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i);
  } catch (e) {
    console.error(e);
  }

  return !!isMobileDevice;
};
